exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-aktualnosci-tsx": () => import("./../../../src/pages/aktualnosci/[...].tsx" /* webpackChunkName: "component---src-pages-aktualnosci-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kontakt-tsx": () => import("./../../../src/pages/kontakt.tsx" /* webpackChunkName: "component---src-pages-kontakt-tsx" */),
  "component---src-pages-materialy-do-pobrania-tsx": () => import("./../../../src/pages/materialy-do-pobrania.tsx" /* webpackChunkName: "component---src-pages-materialy-do-pobrania-tsx" */),
  "component---src-pages-nasze-marki-tsx": () => import("./../../../src/pages/nasze-marki.tsx" /* webpackChunkName: "component---src-pages-nasze-marki-tsx" */),
  "component---src-pages-o-nas-tsx": () => import("./../../../src/pages/o-nas.tsx" /* webpackChunkName: "component---src-pages-o-nas-tsx" */),
  "component---src-templates-post-template-tsx": () => import("./../../../src/templates/post-template.tsx" /* webpackChunkName: "component---src-templates-post-template-tsx" */)
}

